import React from 'react'
import Fade from 'react-awesome-reveal'
import { Link } from 'react-router-dom'
import Form from './Form'

export const Registration = () => {

    window.scrollTo(0, 0);

    return (
        <div className='regbg'> 
            <div className='p-2 mx-4'>
            <h1 className="text-white sttelemedia pt-10 text-center mb-4 text-4xl leading-none tracking-tight font-extrabold md:text-5xl lg:text-6xl">EnterpriseSG D&D 2024 RSVP</h1>
            <h2 className='text-white sttelemedia py-4 text-center mb-4 text-4xl leading-none tracking-tight font-extrabold md:text-3xl lg:text-4xl'>Join us and RSVP now!</h2>
                <Form/>
            </div>
        </div>
    )
}

export default Registration