import React from 'react'
import {db} from '../firebase';
import { collection, getDocs, query, deleteDoc, doc as docSnapshot, where, orderBy, limit, addDoc, updateDoc  } from "firebase/firestore";
import * as XLSX from 'xlsx';

const Download = () => {
    const docRef = query(collection(db, "enterprisesg"))
    const downloadLatest = () => {
        getDocs(docRef)
            .then((snapshot) => {
                let information = []
                snapshot.docs.forEach((doc) => {
                  information.push(doc.data().formData)
            })
            let data = information
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
            XLSX.writeFile(workbook, "enterprisesg.xlsx");
        })
        .catch(err => {
            console.log(err.message)
          })
      };


    
  return (
    <div><button onClick={downloadLatest}>Download</button></div>
  )
}

export default Download