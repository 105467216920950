import React, { useRef, useState, } from 'react'
import Modal from 'react-modal';
import { collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';
import { useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import tShirtSizeChart from './images/tshirt size chart.png'
import tankTopSizeChart from './images/tank top size chart.jpg'
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";

export const Test = () => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          height: 'auto'
        },
      };
      

    const [modalIsOpen, setIsOpen] = useState(false);
    const [openWhich, setOpenWhich] = useState()
    const [locallyBased, setLocallyBased] = useState(false);

    function closeModal() {
        setIsOpen(false)
        setOpenWhich("")
    }
    
    const form = useRef();

    const navigate = useNavigate();

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    
        const Days = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    
        var currentdate = new Date(); 
    
        let h = currentdate.getHours();
        h = h < 10 ? "0" + h : h;
        let m = currentdate.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = currentdate.getSeconds();
        s = s < 10 ? "0" + s : s;
    
    
        var datetime =  Days[currentdate.getDay()] + ", "
                        + currentdate.getDate() + " "
                        + monthNames[currentdate.getMonth()] + " "
                        + currentdate.getFullYear() + ", "  
                        + h + ":"  
                        + m + ":" 
                        + s;
  
    const colRef = collection(db, 'enterprisesg')
    const [formData, setFormData] = useState({
        timestamp: datetime
    })

    function makeNameNice(firstName, lastName, displayName, country, temail) {
        try {
            const firstNameList = firstName.split(" ")
            const lastNameList = lastName.split(" ")
            const displayNameList = displayName.split(" ")
            const countryList = country.split(" ")

            for (let i = 0; i < firstNameList.length; i++) {
                if (firstNameList[i] === '') {

                } else {
                    firstNameList[i] = firstNameList[i].toLowerCase()
                    firstNameList[i] = firstNameList[i][0].toUpperCase() + firstNameList[i].substr(1);
                }
            }
            for (let i = 0; i < lastNameList.length; i++) {
                if (lastNameList[i] === '') {

                } else {
                    lastNameList[i] = lastNameList[i].toLowerCase()
                    lastNameList[i] = lastNameList[i][0].toUpperCase() + lastNameList[i].substr(1);
                }
            }
            for (let i = 0; i < displayNameList.length; i++) {
                if (displayNameList[i] === '') {

                } else {
                    displayNameList[i] = displayNameList[i].toLowerCase()
                    displayNameList[i] = displayNameList[i][0].toUpperCase() + displayNameList[i].substr(1);
                }
            }

            for (let i = 0; i < countryList.length; i++) {
                if (countryList[i] === '') {

                } else {
                    countryList[i] = countryList[i].toLowerCase()
                    countryList[i] = countryList[i][0].toUpperCase() + countryList[i].substr(1);
                }
            }

            let doneFirstName = firstNameList.join(" ")
            let doneLastName = lastNameList.join(" ")
            let doneDisplayName = displayNameList.join(" ")
            let doneEmail = temail.toLowerCase()
            let doneCountry = countryList.join(" ")
            formData.email = doneEmail
            formData.displayName = doneDisplayName
            formData.country = doneCountry
            formData.firstName = doneFirstName
            formData.lastName = doneLastName
            form.current.displayName.value = doneDisplayName
        } catch (err){
            console.log('fail')
            console.log(err)
            return 'fail'
        }
    }
    
    async function sendEmail(e) {
        e.preventDefault()

        await addDoc(colRef, {formData})   

        {/*try {
            try {   
                emailjs.sendForm('service_vdxr0ul', 'template_ckx0m8e', form.current, 'r04-bQl4Di3WPya_p')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                })
            } catch(e) {
                toast.error('Registration was unsuccessful, please try again.', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "light",
                    })
                return
            }
        } catch(e) {
            toast.error('Registration was unsuccessful, please try again.', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                })
            return
        }*/}

        if (formData.attending === "No") {
                toast.success('Thank you for your response. We hope to see you at the next event!', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "light",
                    })
                    window.scrollTo(0, 0)
        } else {
            toast.success('Thank you! You have registered successfully!', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                })
                window.scrollTo(0, 0)
        }

  };

  const handleOpen = (e) => {
    setIsOpen(true)
    setOpenWhich(e.target.value)
  }

  function HandleModal () {
    if (openWhich === 'tShirt') {
        return <div className='text-center'><img className='pb-8 w-full' src={tShirtSizeChart} alt=""/><button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button></div>
    } else if (openWhich === 'tankTop') {
        return <div className='text-center'><img className='pb-8' src={tankTopSizeChart} alt=""/><button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button></div>
    } else {
        return <div><p className='text-center text-lg font-bold py-2'>Terms and Conditions</p>
        <p className='py-4  text-justify'>“By submitting on this platform, I consent for Synonym Private Limited, STT GDC Pte Ltd, and associated parties (including third party service providers and representatives) (STT Persons) to collect, use, disclose, store, retain and/or process (“Use”) my personal data and information in the manner and for the purposes described in their respective personal data policies, <a className='text-blue-600 hover:underline' href="https://www.sttelemediagdc.com/pdpa" rel="noreferrer" target="_blank">https://www.sttelemediagdc.com/pdpa</a>, and in particular for the purposes of processing, servicing and managing my orders and general administration in connection with the foregoing and contacting me at the contacts that I have provided. I confirm the accuracy of the information that I have furnished and further confirm that where I have furnished personal data of other individuals, I have obtained consent from such individuals to disclose such information, except to the extent that such consent is not  under relevant laws. I will indemnify STT Persons for any loss or damage that they may sustain from or in connection with the use of the information that I have furnished and will not hold them liable for any loss or damage that may be incurred by me. My attendance and participation in this event is subject to my availability and all work exigencies.”</p>
        <div className='py-4 text-center'>
        <button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button>
        </div></div>
    }
  }

  const [isAttending, setIsAttending] = useState(true)

  const handleAttending = (bool) => {
    setIsAttending(bool)
  }
    
    return (
    <form ref={form} onSubmit={sendEmail} autoComplete="off" className='bg-white rounded-lg p-10'>
        <ToastContainer/>
        <p className='font-bold py-4 underline pt-8 text-lg'>Admin Instructions:</p>
        <div className='list-decimal'>
            <p className='mt-2'>
            Only Table IC will fill up this form. Please form tables of 10 pax. Registered guests at incomplete tables (i.e. less than 10 pax) may be reassigned or combined with other incomplete table. 
            </p>
            <p className='mt-2'>
            Table allocations will be based on a first-come-first-served basis.
            </p>
            <p className='mt-2'>
            Meals served will have no pork and no lard. Should there be any additional dietary requirements, please ensure to highlight it in this registration form.
                </p>
                <p className='mt-2'>
                Alcohol will not be provided during DnD. Each table can bring their own alcohol, with a corkage fee of <span className='font-bold'>SGD38.50 + GST</span> per bottle of champagne/wine (750ml or less) and <span className='font-bold'>SGD132 + GST</span> per bottle of hard liquor (750ml or less).  
Do note that all alcohol would need to be duty paid and sealed.
                </p>
                <p className='mt-2'>
                A unique QR code with registration and table number will be sent to your registered email address nearing the date of D&D. The QR code must be presented during registration and for the collection of lucky draw prizes, if any.
                </p>
                <p className='mt-2'>
                Registrations close on <span className='font-bold'>30 August</span>, be sure to sign up by then!
                </p>
                <p className='mt-2'>
                If you have any questions, please email the D&D Committee at <a className='underline text-blue-600' href="mailto:enterprisesg_dnd@enterprisesg.gov.sg?body=Hi Team,">enterprisesg_dnd@enterprisesg.gov.sg</a> 
                </p>
        </div>
        <p className='font-bold py-4 underline pt-8 text-lg'>Table IC Particulars</p>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="tableicname" type="text" onInput={(e) => setFormData({...formData, tableicname: e.target.value})} value={formData.tableicname} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  required/>
                <label htmlFor="floating_tableicname" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Table IC Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="tableicemail" type="email" onInput={(e) => setFormData({...formData, tableicemail: e.target.value})} value={formData.tableicemail} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  required/>
                <label htmlFor="floating_tableicemail" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Table IC Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, tableicdivision: e})}} value={formData.tableicdivision} required>
                <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media" color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
            <p className='text-sm font-medium py-2'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input onInput={(e) => setFormData({...formData, tableicpreference: e.target.value})} id="tableicpreference-option-1" type="radio" name="tableicpreference" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " required />
            <label htmlFor="tableicpreference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input onInput={(e) => setFormData({...formData, tableicpreference: e.target.value})} id="tableicpreference-option-2" type="radio" name="tableicpreference" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="tableicpreference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input onInput={(e) => setFormData({...formData, tableicpreference: e.target.value})} id="tableicpreference-option-3" type="radio" name="tableicpreference" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="tableicpreference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="tableicallergy" type="text" onInput={(e) => setFormData({...formData, tableicallergy: e.target.value})} value={formData.tableicallergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_tableicallergy" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <div>
        <p className='font-bold py-4 underline pt-8 text-lg'>Guests Particulars</p>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest1name" type="text" onInput={(e) => setFormData({...formData, guest1name: e.target.value})} value={formData.guest1name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest1name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 1 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest1email" type="email" onInput={(e) => setFormData({...formData, guest1email: e.target.value})} value={formData.guest1email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest1email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 1 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest1division: e})}} value={formData.guest1division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest1preference" onInput={(e) => setFormData({...formData, guest1preference: e.target.value})} id="guest1preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest1preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest1preference" onInput={(e) => setFormData({...formData, guest1preference: e.target.value})} id="guest1preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest1preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest1preference" onInput={(e) => setFormData({...formData, guest1preference: e.target.value})} id="guest1preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest1preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest1allergy" type="text" onInput={(e) => setFormData({...formData, guest1allergy: e.target.value})} value={formData.guest1allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest1" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest1name" type="text" onInput={(e) => setFormData({...formData, guest2name: e.target.value})} value={formData.guest2name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest2name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 2 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest2email" type="email" onInput={(e) => setFormData({...formData, guest2email: e.target.value})} value={formData.guest2email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest2email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 2 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest2division: e})}} value={formData.guest2division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest2preference" onInput={(e) => setFormData({...formData, guest2preference: e.target.value})} id="guest2preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest2preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest2preference" onInput={(e) => setFormData({...formData, guest2preference: e.target.value})} id="guest2preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest2preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest2preference" onInput={(e) => setFormData({...formData, guest2preference: e.target.value})} id="guest2preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest2preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest2allergy" type="text" onInput={(e) => setFormData({...formData, guest2allergy: e.target.value})} value={formData.guest2allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest2" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest3name" type="text" onInput={(e) => setFormData({...formData, guest3name: e.target.value})} value={formData.guest3name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest3name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 3 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest3email" type="email" onInput={(e) => setFormData({...formData, guest3email: e.target.value})} value={formData.guest3email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest3email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 3 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest3division: e})}} value={formData.guest3division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest3preference" onInput={(e) => setFormData({...formData, guest3preference: e.target.value})} id="guest3preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest3preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest3preference" onInput={(e) => setFormData({...formData, guest3preference: e.target.value})} id="guest3preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest3preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest3preference" onInput={(e) => setFormData({...formData, guest3preference: e.target.value})} id="guest3preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest3preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest3allergy" type="text" onInput={(e) => setFormData({...formData, guest3allergy: e.target.value})} value={formData.guest3allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest3" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest4name" type="text" onInput={(e) => setFormData({...formData, guest4name: e.target.value})} value={formData.guest4name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest4name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 4 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest4email" type="email" onInput={(e) => setFormData({...formData, guest4email: e.target.value})} value={formData.guest4email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest4email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 4 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest4division: e})}} value={formData.guest4division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest4preference"  onInput={(e) => setFormData({...formData, guest4preference: e.target.value})} id="guest4preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest4preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest4preference"  onInput={(e) => setFormData({...formData, guest4preference: e.target.value})} id="guest4preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest4preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest4preference" onInput={(e) => setFormData({...formData, guest4preference: e.target.value})} id="guest4preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest4preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest4allergy" type="text" onInput={(e) => setFormData({...formData, guest4allergy: e.target.value})} value={formData.guest4allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest4" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest5name" type="text" onInput={(e) => setFormData({...formData, guest5name: e.target.value})} value={formData.guest5name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest5name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 5 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest5email" type="email" onInput={(e) => setFormData({...formData, guest5email: e.target.value})} value={formData.guest5email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest5email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 5 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest5division: e})}} value={formData.guest5division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest5preference"  onInput={(e) => setFormData({...formData, guest5preference: e.target.value})} id="guest5preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest5preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest5preference" onInput={(e) => setFormData({...formData, guest5preference: e.target.value})} id="guest5preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest5preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest5preference" onInput={(e) => setFormData({...formData, guest5preference: e.target.value})} id="guest5preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest5preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest5allergy" type="text" onInput={(e) => setFormData({...formData, guest5allergy: e.target.value})} value={formData.guest5allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest5" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest6name" type="text" onInput={(e) => setFormData({...formData, guest6name: e.target.value})} value={formData.guest6name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest6name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 6 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest6email" type="email" onInput={(e) => setFormData({...formData, guest6email: e.target.value})} value={formData.guest6email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest6email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 6 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest6division: e})}} value={formData.guest6division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest6preference" onInput={(e) => setFormData({...formData, guest6preference: e.target.value})} id="guest6preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest6preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest6preference" onInput={(e) => setFormData({...formData, guest6preference: e.target.value})} id="guest6preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest6preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest6preference" onInput={(e) => setFormData({...formData, guest6preference: e.target.value})} id="guest6preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest6preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest6allergy" type="text" onInput={(e) => setFormData({...formData, guest6allergy: e.target.value})} value={formData.guest6allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest6" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest7name" type="text" onInput={(e) => setFormData({...formData, guest7name: e.target.value})} value={formData.guest7name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest7name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 7 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest7email" type="email" onInput={(e) => setFormData({...formData, guest7email: e.target.value})} value={formData.guest7email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest7email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 7 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest7division: e})}} value={formData.guest7division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest7preference" onInput={(e) => setFormData({...formData, guest7preference: e.target.value})} id="guest7preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest7preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest7preference" onInput={(e) => setFormData({...formData, guest7preference: e.target.value})} id="guest7preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest7preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest7preference" onInput={(e) => setFormData({...formData, guest7preference: e.target.value})} id="guest7preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest7preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest7allergy" type="text" onInput={(e) => setFormData({...formData, guest7allergy: e.target.value})} value={formData.guest7allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest7" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest8name" type="text" onInput={(e) => setFormData({...formData, guest8name: e.target.value})} value={formData.guest8name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest8name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 8 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest8email" type="email" onInput={(e) => setFormData({...formData, guest8email: e.target.value})} value={formData.guest8email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest8email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 8 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest8division: e})}} value={formData.guest8division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest8preference" onInput={(e) => setFormData({...formData, guest8preference: e.target.value})} id="guest8preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest8preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest8preference" onInput={(e) => setFormData({...formData, guest8preference: e.target.value})} id="guest8preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest8preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest8preference" onInput={(e) => setFormData({...formData, guest8preference: e.target.value})} id="guest8preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest8preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest8allergy" type="text" onInput={(e) => setFormData({...formData, guest8allergy: e.target.value})} value={formData.guest8allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest8" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        <hr className=" h-1 mx-auto my-4 bg-black order-0 my-8"/>
        <div className="mt-4 md:grid md:grid-cols-12 gap-6">
        <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest9name" type="text" onInput={(e) => setFormData({...formData, guest9name: e.target.value})} value={formData.guest9name} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest9name" className="sttelemedia peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 9 Name</label>
            </div>
            <div className="py-2 md:py-0 relative z-0 mb-2 col-span-4 group">
                <input name="guest9email" type="email" onInput={(e) => setFormData({...formData, guest9email: e.target.value})} value={formData.guest9email} className="block text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest9email" className="sttelemedia peer-focus:font-medium absolute  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Guest 9 Email</label>
            </div>
            <div className="py-2 md:py-0 col-span-4">
            <Select color="gray" label="Division" onChange={(e) => {setFormData({...formData, guest9division: e})}} value={formData.guest9division}>
            <Option value="IND - Food Manufacturing & Agritech" color="gray" required>IND - Food Manufacturing & Agritech</Option>
                <Option value="IND - Food Services" color="gray" required>IND - Food Services</Option>
                <Option value="IND - Retail & Design" color="gray" required>IND - Retail & Design</Option>
                <Option value="IND - Services & Heartland Enterprises" color="gray" required>IND - Services & Heartland Enterprises</Option>
                <Option value="IND - Growth Enterprise Development" color="gray" required>IND - Growth Enterprise Development</Option>
                <Option value="IND - Advanced Manufacturing" color="gray" required>IND - Advanced Manufacturing</Option>
                <Option value="IND - General Manufacturing" color="gray" required>IND - General Manufacturing</Option>
                <Option value="IND - Marine & Offshore, Energy & Chemicals" color="gray" required>IND - Marine & Offshore, Energy & Chemicals</Option>
                <Option value="IND - Healthcare & Biomedical" color="gray" required>IND - Healthcare & Biomedical</Option>
                <Option value="IND - Infocomm & Media"color="gray" required>IND - Infocomm & Media</Option>
                <Option value="IND - Urban Systems & Solutions" color="gray" required>IND - Urban Systems & Solutions</Option>
                <Option value="IND - Enterprise Sustainability" color="gray" required>IND - Enterprise Sustainability</Option>
                <Option value="IND - Digitalisation & E-Commerce" color="gray" required>IND - Digitalisation & E-Commerce</Option>
                <Option value="IND - Financing Ecosystem" color="gray" required>IND - Financing Ecosystem</Option>
                <Option value="IND - Corporate Planning & Insights" color="gray" required>IND - Corporate Planning & Insights</Option>
                <Option value="IND - Strategic Planning" color="gray" required>IND - Strategic Planning</Option>
                <Option value="IND - Data & Transformation Office" color="gray" required>IND - Data & Transformation Office</Option>
                <Option value="IND - Incentive Policy" color="gray" required>IND - Incentive Policy</Option>
                <Option value="IND - Incentive Infrastructure" color="gray" required>IND - Incentive Infrastructure</Option>
                <Option value="IND - Incentive Data & Ops" color="gray" required>IND - Incentive Data & Ops</Option>
                <Option value="IND - Customer Innovation" color="gray" required>IND - Customer Innovation</Option>
                <Option value="IND - Partners" color="gray" required>IND - Partners</Option>
                <Option value="MKT - Markets Strategy & Policy" color="gray" required>MKT - Markets Strategy & Policy</Option>
                <Option value="MKT - Transport & Logistics" color="gray" required>MKT - Transport & Logistics</Option>
                <Option value="MKT - Logistics" color="gray" required>MKT - Logistics</Option>
                <Option value="MKT - Trade 1" color="gray" required>MKT - Trade 1</Option>
                <Option value="MKT - Trade 2" color="gray" required>MKT - Trade 2</Option>
                <Option value="MKT - Trade Development & Strategy" color="gray" required>MKT - Trade Development & Strategy</Option>
                <Option value="INNO - Venture Build" color="gray" required>INNO - Venture Build</Option>
                <Option value="INNO - Ecosystem & Community" color="gray" required>INNO - Ecosystem & Community</Option>
                <Option value="INNO - SEEDS Capital" color="gray" required>INNO - SEEDS Capital</Option>
                <Option value="INNO - Global Innovation Network" color="gray" required>INNO - Global Innovation Network</Option>
                <Option value="INNO - Innovation Eco-system Development" color="gray" required>INNO - Innovation Eco-system Development</Option>
                <Option value="QE - Accreditation & Assurance" color="gray" required>QE - Accreditation & Assurance</Option>
                <Option value="QE - Consumer Product Safety, Weights & Measures Office" color="gray" required>QE - Consumer Product Safety, Weights & Measures Office</Option>
                <Option value="QE - Policy & Promotion" color="gray" required>QE - Policy & Promotion</Option>
                <Option value="QE - Standards (Manufacturing)" color="gray" required>QE - Standards (Manufacturing)</Option>
                <Option value="QE - Standards (Services)" color="gray" required>QE - Standards (Services)</Option>
                <Option value="CD - Corporate Communications" color="gray" required>CD - Corporate Communications</Option>
                <Option value="CD - Digital, Data and IT" color="gray" required>CD - Digital, Data and IT</Option>
                <Option value="CD - Finance, Procurement & Administration" color="gray" required>CD - Finance, Procurement & Administration</Option>
                <Option value="HC - People & Culture" color="gray" required>HC - People & Culture</Option>
                <Option value="HC - Enterprise Academy" color="gray" required>HC - Enterprise Academy</Option>
                <Option value="HC - Industry Human Capital" color="gray" required>HC - Industry Human Capital</Option>
                <Option value="HC - Global Workforce" color="gray" required>HC - Global Workforce</Option>
                <Option value="HC - Enterprise Academy - Scholar" color="gray" required>HC - Enterprise Academy - Scholar</Option>
                <Option value="Internal Audit" color="gray" required>Internal Audit</Option>
                <Option value="Leadership Office" color="gray" required>Leadership Office</Option>
                <Option value="MKT - Northeast Asia and Oceania " color="gray" required>MKT - Northeast Asia and Oceania </Option>
                <Option value="MKT - Americas" color="gray" required>MKT - Americas</Option>
                <Option value="MKT - Europe" color="gray" required>MKT - Europe</Option>
                <Option value="MKT - South Asia" color="gray" required>MKT - South Asia</Option>
                <Option value="MKT - Middle East & Africa" color="gray" required>MKT - Middle East & Africa</Option>
                <Option value="MKT - Southeast Asia" color="gray" required>MKT - Southeast Asia</Option>
                <Option value="MKT – China " color="gray" required>MKT – China </Option>
                <Option value="Infrastructure Asia" color="gray" required>Infrastructure Asia</Option>
                <Option value="IPI" color="gray" required>IPI</Option>
                <Option value="Singapore Cooperation Enterprise" color="gray" required>Singapore Cooperation Enterprise</Option>
                <Option value="EnterpriseSG Society" color="gray" required>EnterpriseSG Society</Option>
                <Option value="Others" color="gray" required>Others</Option>
            </Select>
            </div>
            <div className="md:flex w-full col-span-12 py-2">
                       <p className='text-sm font-medium py-2 md:py-0'>Food Preference:</p>
            <fieldset>
            <div className='py-1'>
            <input name="guest9preference" onInput={(e) => setFormData({...formData, guest9preference: e.target.value})} id="guest9preference-option-1" type="radio" value="No Preference" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest9preference-option-1" className="text-sm ml-2 font-medium text-gray-900 ">
            No Preference
            </label>
            </div>
            <div className='py-1'>
            <input name="guest9preference" onInput={(e) => setFormData({...formData, guest9preference: e.target.value})} id="guest9preference-option-2" type="radio" value="Halal" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest9preference-option-2" className=" text-sm ml-2  font-medium text-gray-900 ">
            Halal
            </label>
            </div>
            <div className='py-1'>
            <input name="guest9preference" onInput={(e) => setFormData({...formData, guest9preference: e.target.value})} id="guest9preference-option-3" type="radio" value="Vegetarian" className="ml-4 w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300    " />
            <label htmlFor="guest9preference-option-3" className=" text-sm ml-2  font-medium text-gray-900 ">
            Vegetarian
            </label>
            </div>
            </fieldset>
            </div>
            <p className='col-span-12 text-sm my-4 md:my-0'>Please indicate if you have any food allergies:</p>
            <div className="relative z-0 col-span-12 mb-6 group">
                <input name="guest9allergy" type="text" onInput={(e) => setFormData({...formData, guest9allergy: e.target.value})} value={formData.guest9allergy} className="block md:text-lg py-2.5 px-0 w-full  text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                <label htmlFor="floating_guest9" className="peer-focus:font-medium absolute  text-gray-500 overflow-x-visible duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Allergies (if any)</label>
            </div>
        </div>
        </div>
        <button className="mt-8 text-white bg-green-500 hover:bg-amber-600 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg  w-full sm:w-auto px-5 py-2.5 text-center">Submit Form</button>
    </form>
  );
};

export default Test
